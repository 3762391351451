import { NoRentalHistoryError } from '~/errors/validationError';
import { RentalHistory, RentalHistoryErrorCode } from '~/types/RentalApplication';
import { MagicUseCase } from '~/use-cases/magicUseCase';

export class ValidateRentalHistoryUseCase extends MagicUseCase {
  private addressRegex = /^(?!.*([&][#]))[A-Za-z0-9 #(),&.,'_+~/*-]*$/;
  private usZipRegex = /^[0-9]{5}$/;
  private canZipRegex = /^[A-Za-z][0-9][A-Za-z][\s-]?[0-9][A-Za-z][0-9]$/;
  protected async runLogic() {
    this.getState().user.rentalApplication.rentalHistoryErrors = [];
    this.validateRentalHistory();
  }

  private validateRentalHistory = () => {
    if (this.getState().user.rentalApplication.application?.residentialHistory) {
      this.getState().user.rentalApplication.rentalHistoryErrors = this.getHistoryErrorCodes(
        this.getState().user.rentalApplication?.application?.residentialHistory || []
      );
    }
  };

  private getHistoryErrorCodes = (history: RentalHistory[]): RentalHistoryErrorCode[][] => {
    if (history.length === 0) {
      throw new NoRentalHistoryError();
    }

    return history.map((historyElement: RentalHistory) => {
      const errors: RentalHistoryErrorCode[] = [];
      this.addErrorToArray(errors, this.validateAddress1(historyElement.address?.streetAddress1));
      this.addErrorToArray(errors, this.validateAddress2(historyElement.address?.streetAddress2));
      this.addErrorToArray(errors, this.validateReasonForLeaving(historyElement.reasonForLeaving));
      this.addErrorToArray(errors, this.validateCity(historyElement.address?.city));
      this.addErrorToArray(errors, this.validateState(historyElement.address?.state));
      this.addErrorToArray(errors, this.validateZipCode(historyElement.address?.country, historyElement.address?.zipCode));
      this.addErrorToArray(errors, this.validateCountry(historyElement.address?.country));
      this.addErrorToArray(errors, this.validateLandlordPhone(historyElement.landlordPhone));
      this.addErrorToArray(errors, this.validateRent(historyElement.rent));
      this.addErrorToArray(errors, this.validateMoveInDate(historyElement.moveInDate));
      return errors;
    });
  };

  private addErrorToArray = (errors: RentalHistoryErrorCode[], error?: RentalHistoryErrorCode) => {
    if (error) {
      errors.push(error);
    }
  };

  private validateAddress1 = (address1?: string): RentalHistoryErrorCode | undefined => {
    if (!address1 || address1.length === 0 || address1.length > 50 || !this.addressRegex.test(address1)) {
      return RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress1;
    }
  };

  private validateAddress2 = (address2?: string): RentalHistoryErrorCode | undefined => {
    if (address2 && (address2.length > 100 || !this.addressRegex.test(address2))) {
      return RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress2;
    }
  };

  private validateReasonForLeaving = (reasonForLeavin?: string): RentalHistoryErrorCode | undefined => {
    if (reasonForLeavin && reasonForLeavin.length > 500) {
      return RentalHistoryErrorCode.InvalidRentalHistoryReansonForLeaving;
    }
  };

  private validateCity = (city?: string): RentalHistoryErrorCode | undefined => {
    if (!city || city.length < 2 || city.length > 27 || !this.addressRegex.test(city)) {
      return RentalHistoryErrorCode.InvalidRentalHistoryCity;
    }
  };

  private validateState = (state?: string): RentalHistoryErrorCode | undefined => {
    if (!state || state.length !== 2) {
      return RentalHistoryErrorCode.InvalidRentalHistoryState;
    }
  };

  private validateZipCode = (country?: string, zip?: string): RentalHistoryErrorCode | undefined => {
    if (!country || !zip) {
      return RentalHistoryErrorCode.InvalidRentalHistoryZipCode;
    }
    if (country === 'USA' && !this.usZipRegex.test(zip)) {
      return RentalHistoryErrorCode.InvalidRentalHistoryZipCode;
    } else if (country === 'CAN' && !this.canZipRegex.test(zip)) {
      return RentalHistoryErrorCode.InvalidRentalHistoryZipCode;
    }
    return undefined;
  };

  private validateCountry = (country?: string): RentalHistoryErrorCode | undefined => {
    if (!country || country.length < 3 || country.length > 25) {
      return RentalHistoryErrorCode.InvalidRentalHistoryCountry;
    }
  };

  private validateLandlordPhone = (phone?: string): RentalHistoryErrorCode | undefined => {
    if (phone && !this.validatePhone(phone)) {
      return RentalHistoryErrorCode.InvalidRentalHistoryLandlordPhone;
    }
  };

  private validatePhone = (phone: string | undefined): boolean => {
    if (!phone) {
      return false;
    }
    const digitsOnly = phone.replace(/\D+/g, '');
    if (/^(00|\+)/.test(phone)) {
      return phone.length > 3;
    } else {
      return digitsOnly.length === 10;
    }
  };

  private validateRent = (rent?: number): RentalHistoryErrorCode | undefined => {
    if (!rent || rent < 0) {
      return RentalHistoryErrorCode.InvalidRentalHistoryRent;
    }
  };

  private validateMoveInDate = (moveInDate?: string): RentalHistoryErrorCode | undefined => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    moveInDate = moveInDate || '';
    if (moveInDate.length === 0 || !dateRegex.test(moveInDate)) {
      return RentalHistoryErrorCode.InvalidRentalHistoryMoveInDate;
    }
  };
}
