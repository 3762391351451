import { useNavigate, useParams } from '@solidjs/router';
import { Component, createEffect, For, onMount, Show } from 'solid-js';
import { ADD_RENTAL_HISTORY, NEXT, PREVIOUS } from '~/assets/strings';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import { Button } from '~/components/common/buttons';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { PresentableRentalHistory, RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { RentalApplicationStep } from '~/types/RentalApplicationSteps';
import { UpdateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/updateRentalApplicationInfoUseCase';
import { ValidateRentalHistoryUseCase } from '~/use-cases/rental-applications/application-steps/validateRentalHistoryUseCase';
import { GetRentalApplicationUseCase } from '~/use-cases/rental-applications/getRentalApplicationUseCase';
import { GoToNextStepUseCase } from '~/use-cases/rental-applications/goToNextStepUseCase';
import { RentalHistoryItem } from './RentalHistoryItem';

export const RentalHistoryStep: Component = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const params = useParams();
  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: getRentalApplication, isLoading: isLoadingApplication } = useUseCase(GetRentalApplicationUseCase);
  const { execute: updateRentalApplication } = useUseCase(UpdateRentalApplicationInfoUseCase);
  const { execute: goToNextStep, isLoading: isGoingToNextStep } = useUseCase(GoToNextStepUseCase);
  const { execute: validateRentalHistory, didSucceed: validationDidSucceed } = useUseCase(ValidateRentalHistoryUseCase);

  const addNewRentalHistory = () => {
    const history = [...(presentableRentalApplication()?.rentalHistory ?? []), {}];
    updateRentalApplication({
      history: history,
    });
  };

  const onRemoveRentalHistory = (index: number) => {
    const history = presentableRentalApplication()?.rentalHistory?.filter((_, i) => i !== index);
    updateRentalApplication({
      history: history,
    });
  };

  const onUpdateHistoryItem = (historyItem: PresentableRentalHistory, index: number) => {
    const history = (presentableRentalApplication()?.rentalHistory ?? []).map((item, i) => (i === index ? historyItem : item));
    updateRentalApplication({
      history: history,
    });
  };

  onMount(() => {
    getRentalApplication({ id: params.id, password: params.password });
  });

  createEffect(() => {
    if (!validationDidSucceed() || presentableRentalApplication()?.errors?.hasRentalHistoryError) {
      return;
    }
    goToNextStep({
      currentStep: RentalApplicationStep.RentalHistory,
      credentials: { id: params.id, password: params.password },
    });
  });

  const onNext = async () => {
    validateRentalHistory();
  };

  return (
    <Show
      when={!isLoadingApplication() && !isGoingToNextStep()}
      fallback={
        <div class="flex h-section3">
          <ActivityIndicator />
        </div>
      }>
      <div class="scrollbar flex h-section3 flex-col overflow-y-auto bg-white p-5">
        <For each={presentableRentalApplication()?.rentalHistory}>
          {(history, index) => (
            <RentalHistoryItem
              history={history}
              index={index()}
              errors={presentableRentalApplication()?.rentalHistoryErrors?.[index()]}
              onUpdate={onUpdateHistoryItem}
              onDelete={onRemoveRentalHistory}
            />
          )}
        </For>
        <button class="mt-4 w-48 text-left text-primary-color hover:underline" type="button" onClick={addNewRentalHistory}>
          + {t(ADD_RENTAL_HISTORY)}
        </button>
      </div>
      <div class="fixed bottom-0 left-0 z-20 w-full border-t border-gray-200 bg-white px-4 py-3">
        <div class="flex justify-between">
          <Button type="button" onClick={() => navigate(`/rental-applications/${params.id}/${params.password}/potential-units`)}>
            {t(PREVIOUS)}
          </Button>
          <Button type="button" onClick={onNext}>
            {t(NEXT)}
          </Button>
        </div>
      </div>
    </Show>
  );
};
